export const destiData = [
  {
    id: 1,
    icon: "desti1",
    title: "London",
    description: "UK",
    days: 12,
    price: "AED 46999.00",
    description:
      "London is a major city and the capital of the United Kingdom. It's known for its rich history, cultural diversity, and iconic landmarks such as the Tower of London, Buckingham Palace, the British Museum, and the Houses of Parliament with Big Ben. London is also a global financial hub and a center for arts, entertainment, and education.",
    details: [
      {
        title: "Iconic Landmarks",
        description: `The British Museum: Home to a vast collection of art and artifacts from around the world, including the Rosetta Stone and the Elgin Marbles.
The Tower of London: A historic castle and former royal palace, known for its role in British history and as the home of the Crown Jewels.
Buckingham Palace: The official residence of the British monarch, famous for the Changing of the Guard ceremony.`,
      },
      {
        title: "Cultural Attractions",
        description: `West End Theatre District: Known for its world-class theaters and musicals, with popular shows and performances in venues like the Royal Opera House and the National Theatre.
Tate Modern and Tate Britain: Major art galleries featuring modern and historical British art, including works by renowned artists such as Turner and Hockney.`,
      },
      {
        title: "Historic Sites",
        description: `St. Paul’s Cathedral: Designed by Sir Christopher Wren, known for its iconic dome and impressive interior. Visitors can climb to the top for panoramic views of the city.
The Houses of Parliament and Big Ben: Iconic symbols of London, located along the River Thames, and the seat of the UK Parliament.`,
      },
      {
        title: "Parks and Green Spaces",
        description: `Hyde Park: One of London’s largest and most famous parks, offering boating, open-air concerts, and tranquil spots for relaxation.
Regent’s Park: Known for its beautiful gardens, including the Queen Mary’s Gardens and the Open Air Theatre.`,
      },
      {
        title: "Shopping and Dining",
        description: `Oxford Street: A major shopping destination with a wide range of stores, from high street to luxury brands.
Covent Garden: A lively area known for its market, street performers, boutiques, and restaurants.
Borough Market: A historic food market offering a diverse range of gourmet foods, fresh produce, and international cuisine.`,
      },
      {
        title: "Museums and Galleries",
        description: `Natural History Museum: Famous for its dinosaur skeletons, interactive exhibits, and stunning architecture.
Victoria and Albert Museum: Known for its extensive collection of decorative arts and design, including fashion, textiles, and ceramics.`,
      },
      {
        title: "Neighborhoods and Districts",
        description: `Soho: A vibrant district known for its nightlife, entertainment, and diverse dining options.
Camden Market: An eclectic market area known for its alternative fashion, live music, and street food.
Notting Hill: Famous for its colorful houses, Portobello Road Market, and the annual Notting Hill Carnival.
`,
      },
      {
        title: "Transportation",
        description: `London Underground (Tube): An extensive and efficient subway system that connects various parts of the city.
Red Buses: Iconic double-decker buses that offer a convenient way to travel and see the city.
Black Cabs: Traditional London taxis known for their distinctive appearance and knowledgeable drivers.`,
      },
      {
        title: "Events and Festivals",
        description: `The London Eye: A giant Ferris wheel on the South Bank offering spectacular views of the city.
New Year’s Eve Fireworks: A renowned event with a spectacular fireworks display over the Thames, attracting thousands of visitors.`,
      },
      {
        title: "Cultural Diversity",
        description: `London is one of the most culturally diverse cities in the world, with a wide range of cultural festivals, international cuisine, and communities from around the globe.`,
      },
    ],
  },
  {
    id: 2,
    icon: "desti2",
    title: "Maldives",
    description: "Maldives",
    days: 15,
    price: "AED 52643.00",
    description:
      "The Maldives is an island nation located in the Indian Ocean, southwest of Sri Lanka and India. It's renowned for its stunning natural beauty, crystal-clear waters, white sandy beaches, and vibrant coral reefs. Here are some key highlights about the Maldives.",
    details: [
      {
        title: "Resorts and Luxury Tourism",
        description: `The Maldives is famous for its luxury resorts, many of which are located on private islands. These resorts often feature overwater bungalows, fine dining, and a range of water-based activities. `,
      },
      {
        title: "Marine Life and Scuba Diving",
        description: `The Maldives is a top destination for scuba diving and snorkeling due to its rich marine biodiversity. The coral reefs are home to a variety of sea creatures, including manta rays, whale sharks, and colorful tropical fish.`,
      },
      {
        title: "Culture and Heritage",
        description: `While primarily known for its natural beauty, the Maldives also has a rich cultural heritage. Malé, the capital city, has several historic sites, including the Hukuru Miskiy (Old Friday Mosque) and the Maldives National Museum.`,
      },
      {
        title: "Sustainable Tourism and Environmental Concerns",
        description: `The Maldives is highly vulnerable to climate change, particularly rising sea levels, which pose a threat to its low-lying islands. As a result, there is a strong focus on sustainable tourism practices to protect the environment.`,
      },
      {
        title: "Watersports and Adventure",
        description: ` In addition to diving, the Maldives offers a range of watersports like windsurfing, jet-skiing, and fishing. The calm, warm waters make it an ideal spot for these activities.`,
      },
      {
        title: "Romantic Getaways and Honeymoons",
        description: ` The Maldives is a popular destination for romantic vacations and honeymoons, thanks to its secluded and idyllic setting. Many resorts offer special packages for couples.`,
      },
    ],
  },
  {
    id: 3,
    icon: "desti3",
    title: "Moscow",
    description: "Moscow",
    days: 8,
    price: "AED 38000.00",
    description:
      "Moscow is the capital and largest city of Russia, known for its rich history, cultural landmarks, and political significance. Here are some key highlights about Moscow.",
    details: [
      {
        title: "Red Square and the Kremlin",
        description: `Red Square is the heart of Moscow and a UNESCO World Heritage site. It is bordered by the Kremlin, the official residence of the President of Russia, and home to historic buildings like the iconic St. Basil's Cathedral, known for its colorful onion-shaped domes.`,
      },
      {
        title: "Saint Basil's Cathedral",
        description: `One of Moscow's most recognizable landmarks, this cathedral is known for its unique architecture and vibrant colors. It was commissioned by Ivan the Terrible in the 16th century to commemorate a military victory.`,
      },
      {
        title: "The Bolshoi Theatre",
        description: `Famous for its ballet and opera performances, the Bolshoi Theatre is a symbol of Russian culture and a must-visit for fans of classical music and dance.`,
      },
      {
        title: "Moscow Metro",
        description: ` The Moscow Metro is renowned not only for its efficiency but also for its stunning architecture and decor. Many stations are adorned with chandeliers, mosaics, and statues, making it one of the most beautiful subway systems in the world.`,
      },
      {
        title: "Gorky Park",
        description: `A large park offering a variety of recreational activities, including boating, biking, and cultural events. It's a popular spot for both locals and tourists.`,
      },
      {
        title: "Tretyakov Gallery",
        description: `This gallery houses one of the most significant collections of Russian art, including iconic works by artists such as Andrei Rublev and Ilya Repin.`,
      },
      {
        title: "The Cathedral of Christ the Savior",
        description: `The largest Orthodox church in Russia, it was originally constructed in the 19th century, demolished during the Soviet era, and rebuilt in the 1990s.`,
      },
      {
        title: "Moscow State University",
        description: `One of the most prestigious universities in Russia, its main building is an architectural landmark and one of the "Seven Sisters" skyscrapers.`,
      },
      {
        title: "Izmailovsky Market",
        description: `A popular market for souvenirs, antiques, and Russian handicrafts, offering everything from traditional matryoshka dolls to Soviet memorabilia.`,
      },
      {
        title: "Camden Market",
        description: `A vibrant market known for its eclectic mix of shops, food stalls, and live music venues.`,
      },
    ],
  },
  {
    id: 4,
    icon: "desti4",
    title: "Florida",
    description: "Florida",
    days: 18,
    price: "AED 52000.00",
    description:
      "Florida is a diverse state in the southeastern United States, known for its warm climate, beautiful beaches, and vibrant cultural scene. Here are some key highlights about Florida",
    details: [
      {
        title: "Tourist Attractions and Theme Parks",
        description: `Florida is home to some of the world's most famous theme parks, including Walt Disney World, Universal Studios, and SeaWorld, all located in the Orlando area. These parks attract millions of visitors each year and offer a wide range of entertainment and attractions.`,
      },
      {
        title: "Beaches and Coastal Cities",
        description: `Florida boasts an extensive coastline along both the Atlantic Ocean and the Gulf of Mexico, featuring famous beaches like Miami Beach, Clearwater Beach, and Daytona Beach. These areas are popular for sunbathing, water sports, and nightlife.`,
      },
      {
        title: "The Florida Keys",
        description: `An archipelago located off the southern coast of Florida, the Keys are known for their tropical climate, coral reefs, and laid-back lifestyle. Key West, the southernmost point in the continental U.S., is a popular destination for its historic sites, vibrant arts scene, and sunsets at Mallory Square.`,
      },
      {
        title: "Miami",
        description: `A major cultural and economic hub, Miami is known for its Art Deco architecture, diverse culture, and vibrant nightlife. The city is also a gateway to Latin America and the Caribbean, influencing its cuisine, music, and arts.`,
      },
      {
        title: "Everglades National Park",
        description: `A UNESCO World Heritage site, the Everglades is a unique ecosystem of wetlands, home to diverse wildlife including alligators, manatees, and various bird species. The park offers opportunities for airboat tours, kayaking, and wildlife viewing.`,
      },
      {
        title: "Space Coast and the Kennedy Space Center",
        description: `Located on the east coast of Florida, this area is known for its association with the U.S. space program. Visitors can tour the Kennedy Space Center, learn about space exploration, and even watch rocket launches.`,
      },
      {
        title: "Sports and Outdoor Activities",
        description: ` Florida offers a range of outdoor activities, including golfing, fishing, boating, and hiking. The state is also home to professional sports teams in football, basketball, baseball, and hockey.`,
      },
      {
        title: "Cultural Diversity",
        description: `Florida is known for its cultural diversity, with significant Hispanic and Caribbean influences. This diversity is reflected in the state's festivals, cuisine, music, and arts.`,
      },
      {
        title: "Climate",
        description: `Florida's climate varies from subtropical in the north to tropical in the south. It is known for warm weather year-round, making it a popular destination for tourists and retirees. However, the state is also prone to hurricanes, particularly during the Atlantic hurricane season from June to November.`,
      },
      {
        title: "Cultural and Historical Sites",
        description: `Florida has a rich history, including Spanish colonial architecture in St. Augustine, the oldest continuously inhabited European-established settlement in the continental U.S. The state also has a significant Native American heritage, with several sites preserving this history.`,
      },
    ],
  },
  {
    id: 5,
    icon: "desti5",
    title: "Paris",
    description: "Paris",
    days: 14,
    price: "AED 44000.00",
    description:
      "Paris, the capital of France, is one of the most famous and visited cities in the world. Known as the City of Light (La Ville Lumière), Paris is renowned for its art, culture, history, and architecture. Here are some key highlights about Paris",
    details: [
      {
        title: "Eiffel Tower",
        description: `The Eiffel Tower is one of the most iconic landmarks in the world and a symbol of Paris. Built in 1889 for the World's Fair, it offers stunning views of the city from its observation decks.`,
      },
      {
        title: "Louvre Museum",
        description: ` The Louvre is the world's largest art museum and a historic monument. It's home to thousands of works of art, including the Mona Lisa, the Venus de Milo, and the Winged Victory of Samothrace.`,
      },
      {
        title: "Notre-Dame Cathedral",
        description: `A masterpiece of Gothic architecture, Notre-Dame is one of Paris's most famous landmarks. Though it was damaged by fire in 2019, restoration efforts are ongoing. The cathedral is known for its stunning facade, stained glass windows, and gargoyles.`,
      },
      {
        title: "Champs-Élysées and Arc de Triomphe",
        description: `The Avenue des Champs-Élysées is a major boulevard known for its theaters, cafes, and luxury shops. It leads to the Arc de Triomphe, a monumental arch honoring those who fought and died for France in the French Revolutionary and Napoleonic Wars.`,
      },
      {
        title: "Montmartre and the Basilica of the Sacré-Cœur",
        description: `Montmartre is a historic district known for its artistic history, bohemian atmosphere, and the beautiful Basilica of the Sacré-Cœur, which sits atop a hill offering panoramic views of Paris.`,
      },
      {
        title: "Seine River and its Bridges",
        description: `The Seine River flows through the heart of Paris, and its banks are lined with historic buildings and landmarks. The city is also famous for its bridges, including the Pont Neuf and Pont Alexandre III.`,
      },
      {
        title: "Musée d'Orsay",
        description: `Housed in a former railway station, the Musée d'Orsay is known for its extensive collection of Impressionist and Post-Impressionist masterpieces by artists such as Monet, Van Gogh, Degas, and Renoir.`,
      },
      {
        title: "Palace of Versailles",
        description: `Located just outside Paris, the Palace of Versailles is a former royal residence known for its opulent architecture, expansive gardens, and the Hall of Mirrors. It's a UNESCO World Heritage site and a symbol of the absolute monarchy of the Ancien Régime.`,
      },
      {
        title: "Culinary Scene",
        description: `Paris is a global culinary capital, famous for its patisseries, cafes, and restaurants. The city offers a wide range of French cuisine, from gourmet dining to casual bistros and street food like crepes and baguettes.`,
      },
      {
        title: "Cultural Life",
        description: `Paris has a vibrant cultural scene, with numerous theaters, concert halls, and opera houses. The city is also known for its fashion industry, hosting major events like Paris Fashion Week.`,
      },
      {
        title: "Parks and Gardens",
        description: `Paris is home to many beautiful parks and gardens, including the Luxembourg Gardens, Tuileries Garden, and Bois de Boulogne, which offer a peaceful escape from the city's hustle and bustle.`,
      },
    ],
  },
  {
    id: 6,
    icon: "desti6",
    title: "Thailand",
    description: "Thailand",
    days: 11,
    price: "AED 30708.00",
    description:
      "Thailand, located in Southeast Asia, is known for its rich culture, stunning landscapes, vibrant cities, and warm hospitality. It's a popular tourist destination for its diverse offerings, from bustling urban centers to tranquil islands and historical sites. Here are some key highlights about Thailand",
    details: [
      {
        title: "Bangkok",
        description: ` The capital city, Bangkok, is a bustling metropolis known for its vibrant street life, cultural landmarks, and modern amenities. Key attractions include the Grand Palace, Wat Pho (Temple of the Reclining Buddha), and Wat Arun (Temple of Dawn). The city's street markets, shopping malls, and nightlife are also major draws.`,
      },
      {
        title: "Thai Cuisine",
        description: `Thailand is renowned for its flavorful cuisine, which balances sweet, sour, salty, and spicy flavors. Famous dishes include Pad Thai, Tom Yum Goong (spicy shrimp soup), Green Curry, Som Tum (papaya salad), and various street food offerings.`,
      },
      {
        title: "Islands and Beaches",
        description: `Thailand boasts some of the world's most beautiful beaches and islands, such as Phuket, Koh Samui, Krabi, and the Phi Phi Islands. These destinations are known for their white sandy beaches, clear waters, and opportunities for diving, snorkeling, and relaxation.`,
      },
      {
        title: "Chiang Mai and Northern Thailand",
        description: `The northern region, particularly Chiang Mai, is known for its cooler climate, mountainous terrain, and rich cultural heritage. The area is famous for its ancient temples, traditional crafts, and festivals such as the Yi Peng Lantern Festival.`,
      },
      {
        title: "Ayutthaya and Historical Sites",
        description: `The ancient city of Ayutthaya, a UNESCO World Heritage site, offers a glimpse into Thailand's past with its well-preserved ruins of temples and palaces. Sukhothai, another historical city, is also significant for its ancient ruins and historical park.`,
      },
      {
        title: "Buddhist Culture and Temples",
        description: `Buddhism plays a central role in Thai culture, and the country is dotted with thousands of temples (wats). Important temples include Wat Phra Kaew in Bangkok, Wat Phra That Doi Suthep in Chiang Mai, and Wat Rong Khun (the White Temple) in Chiang Rai.`,
      },
      {
        title: "Festivals and Events",
        description: `Thailand celebrates numerous festivals, such as Songkran (Thai New Year), celebrated with water fights, and Loy Krathong, where people release floating baskets into water as a form of spiritual cleansing.`,
      },
      {
        title: "Natural Beauty and National Parks",
        description: `Thailand's natural landscapes include lush jungles, mountains, waterfalls, and national parks. Popular destinations for nature lovers include Khao Yai National Park, Doi Inthanon (the highest mountain in Thailand), and Erawan National Park with its tiered waterfalls.`,
      },
      {
        title: "Thai Hospitality and Culture",
        description: `Known as the "Land of Smiles," Thailand is famous for the friendliness and warmth of its people. Thai culture places a strong emphasis on respect, family, and community.`,
      },
      {
        title: "Thai Traditional Arts and Crafts",
        description: `Thailand has a rich tradition of arts and crafts, including silk weaving, pottery, wood carving, and traditional dance and music. Visitors can explore these crafts in local markets and cultural centers.`,
      },
    ],
  },
  {
    id: 7,
    icon: "desti7",
    title: "Kashmir",
    description: "Kashmir",
    days: 9,
    price: "AED 21934.00",
    description:
      "Kashmir, a region located in the northern part of the Indian subcontinent, is known for its stunning natural beauty, rich cultural heritage, and complex political history. Here are some key highlights about Kashmir",
    details: [
      {
        title: "Natural Beauty",
        description: `Kashmir is often referred to as "Paradise on Earth" due to its breathtaking landscapes. The region is characterized by lush valleys, snow-capped mountains, serene lakes, and vibrant gardens. Popular natural attractions include the Dal Lake, known for its houseboats and Shikara rides, the Mughal Gardens, and the scenic meadows of Gulmarg and Pahalgam.`,
      },
      {
        title: "Srinagar",
        description: `The summer capital of the union territory of Jammu and Kashmir in India, Srinagar is famous for its beautiful gardens, historic architecture, and waterways. The Dal Lake and Nigeen Lake are central to Srinagar's charm, offering picturesque views and houseboat stays.`,
      },
      {
        title: "Cultural Heritage",
        description: `Kashmir has a rich cultural heritage influenced by its history, which includes Buddhist, Hindu, and Muslim traditions. The region is known for its handicrafts, such as Pashmina shawls, carpets, papier-mâché products, and woodwork. The traditional cuisine, known as Kashmiri Wazwan, is also famous for its rich flavors and dishes like Rogan Josh, Yakhni, and Kahwah (a traditional green tea).`,
      },
      {
        title: "Gulmarg and Pahalgam",
        description: `Gulmarg is a popular destination for skiing and winter sports, while Pahalgam is known for its picturesque landscapes and as a starting point for the annual Amarnath Yatra pilgrimage. Both locations are also favored for trekking and nature walks.`,
      },
      {
        title: "Religious and Historic Sites",
        description: `Kashmir is home to numerous significant religious sites, including the Shankaracharya Temple, Hazratbal Shrine, and the ancient Martand Sun Temple. The region also has a number of Sufi shrines and mosques.`,
      },
      {
        title: "Political and Historical Context",
        description: `Kashmir has a complex political history and is a disputed territory claimed by both India and Pakistan, and partly by China. The region has experienced conflict and unrest, particularly since the partition of British India in 1947. The Line of Control (LoC) separates the Indian-administered and Pakistani-administered areas of Kashmir.`,
      },
      {
        title: "Climate",
        description: `Kashmir experiences a temperate climate with four distinct seasons. It is known for its pleasant summers, while winters can be cold with heavy snowfall in the higher altitudes, making it a popular destination for winter sports enthusiasts.`,
      },
      {
        title: "Tourism and Travel",
        description: `While Kashmir's beauty attracts tourists from around the world, travel can sometimes be affected by the region's political situation. Travelers are advised to stay informed about current conditions and follow any travel advisories.`,
      },
      {
        title: "Flora and Fauna",
        description: `The region's diverse ecosystems support a variety of flora and fauna. Wildlife enthusiasts may find species such as the Kashmir stag (Hangul), snow leopard, Himalayan black bear, and a variety of bird species in the region's forests and national parks.`,
      },
    ],
  },
  {
    id: 8,
    icon: "desti8",
    title: "Bali",
    description: "Bali",
    days: 12,
    price: "AED 28515.00",
    description:
      "Bali is an Indonesian island known for its stunning landscapes, rich culture, vibrant arts scene, and welcoming hospitality. It's one of the most popular tourist destinations in Southeast Asia. Here are some key highlights about Bali",
    details: [
      {
        title: "Natural Beauty",
        description: `Bali is renowned for its diverse natural landscapes, including lush rice terraces, volcanic mountains, and beautiful beaches. The island's beaches, such as Kuta, Seminyak, Nusa Dua, and Jimbaran, are famous for surfing, sunbathing, and vibrant nightlife. The central and northern parts of the island feature scenic areas like Ubud, known for its terraced rice paddies and cultural heart.`,
      },
      {
        title: "Culture and Temples",
        description: `Balinese culture is deeply rooted in Hindu traditions, and the island is dotted with thousands of temples, known as "pura." Some of the most famous temples include Tanah Lot, perched on a rock formation in the sea; Uluwatu Temple, known for its dramatic cliff-top setting and Kecak dance performances; and Besakih Temple, the largest and most important temple complex in Bali, often referred to as the "Mother Temple."`,
      },
      {
        title: "Arts and Crafts",
        description: `Bali has a rich artistic heritage, including traditional dance, music, painting, sculpture, and crafts. The town of Ubud is a cultural hub, home to art galleries, workshops, and performances of traditional Balinese dance and music.`,
      },
      {
        title: "Spiritual and Wellness Tourism",
        description: `Bali is also known as a center for wellness and spiritual tourism. Visitors come for yoga retreats, meditation centers, and spa treatments that combine traditional Balinese techniques with modern wellness practices.`,
      },
      {
        title: "Culinary Scene",
        description: `Balinese cuisine is a highlight for many visitors, offering a variety of dishes that are flavorful and unique. Some popular Balinese dishes include Babi Guling (suckling pig), Nasi Goreng (fried rice), Satay, and Lawar (a traditional mix of vegetables, coconut, and meat). The island also offers a range of international cuisines, catering to diverse tastes.`,
      },
      {
        title: "Outdoor Activities and Adventure",
        description: `ali offers numerous outdoor activities, including surfing, diving, snorkeling, trekking, and white-water rafting. The island's diverse landscape, from beaches to mountains, provides opportunities for adventure sports and exploration.`,
      },
      {
        title: "Festivals and Ceremonies",
        description: `Balinese culture is characterized by frequent religious ceremonies and festivals, which are colorful and elaborate. Nyepi, the Balinese Day of Silence, and Galungan, a major Hindu festival, are significant events. These celebrations often include traditional music, dance, and rituals.`,
      },
      {
        title: "Bali's Islands and Marine Life",
        description: `Besides the main island, Bali is surrounded by smaller islands like Nusa Penida, Nusa Lembongan, and Nusa Ceningan, known for their pristine beaches, coral reefs, and marine life. These islands are popular for diving, snorkeling, and exploring natural beauty.`,
      },
      {
        title: "Sustainable Tourism",
        description: `In recent years, there has been a growing emphasis on sustainable tourism in Bali, with efforts to preserve the island's natural beauty and cultural heritage while managing the environmental impact of tourism.`,
      },
      {
        title: "Warm Hospitality",
        description: `Known for the friendliness and warmth of its people, Bali offers a welcoming atmosphere for travelers. The local Balinese are known for their strong sense of community and deep spirituality, which are integral parts of everyday life on the island.`,
      },
    ],
  },
  {
    id: 9,
    icon: "desti9",
    title: "Egypt",
    description: "Egypt",
    days: 15,
    price: "AED 39482.00",
    description:
      "Egypt is a country located in northeastern Africa, known for its rich history, ancient monuments, and diverse cultural heritage. Here are some key highlights about Egypt",
    details: [
      {
        title: "Ancient History and Monuments",
        description: `Egypt is famous for its ancient civilization, which dates back thousands of years. The country is home to iconic monuments such as the Pyramids of Giza, the Great Sphinx, the temples of Luxor and Karnak, and the Valley of the Kings. These sites are remnants of ancient Egyptian dynasties and are major attractions for tourists.`,
      },
      {
        title: "Cairo",
        description: `The capital city, Cairo, is one of the largest cities in Africa and the Middle East. It is a vibrant metropolis known for its historic Islamic architecture, bustling markets like Khan El Khalili, and cultural institutions such as the Egyptian Museum, which houses an extensive collection of ancient artifacts, including treasures from the tomb of Tutankhamun.`,
      },
      {
        title: "Nile River",
        description: `The Nile, the longest river in the world, is a lifeline for Egypt, providing water, fertile land, and transportation. The river has been central to Egyptian civilization for millennia. Cruises on the Nile, particularly between Luxor and Aswan, are popular for exploring the country's ancient sites and enjoying the scenic landscapes.`,
      },
      {
        title: "Luxor and Aswan",
        description: `Luxor is often referred to as the "world's greatest open-air museum" due to its numerous ancient monuments, including the Valley of the Kings, Valley of the Queens, and the Karnak and Luxor temples. Aswan, located further south, is known for its beautiful Nile views, the Aswan High Dam, and the nearby Philae Temple.`,
      },
      {
        title: "Alexandria",
        description: `Located on the Mediterranean coast, Alexandria is Egypt's second-largest city. It has a rich history and was founded by Alexander the Great. The city is known for its Greco-Roman landmarks, including the famous Library of Alexandria (now a modern cultural center), the Qaitbay Citadel, and the Catacombs of Kom El Shoqafa.`,
      },
      {
        title: "Deserts and Oases",
        description: `Egypt's landscape includes vast deserts, such as the Western Desert and the Eastern Desert. These regions are known for their unique geology, oases like Siwa and Bahariya, and opportunities for desert safaris and exploration of ancient rock art.`,
      },
      {
        title: "Red Sea Coast",
        description: `Egypt's Red Sea coast is renowned for its beautiful beaches, clear waters, and coral reefs, making it a popular destination for diving, snorkeling, and water sports. Resorts in areas like Sharm El Sheikh, Hurghada, and Marsa Alam cater to tourists seeking both relaxation and adventure.`,
      },
      {
        title: "Cultural Heritage and Festivals",
        description: `Egypt has a rich cultural heritage influenced by its ancient history, Islamic traditions, and modern developments. The country celebrates various festivals, including religious holidays like Ramadan and Eid, and the Pharaonic festival of Opet.`,
      },
      {
        title: "Cuisine",
        description: `Egyptian cuisine is flavorful and diverse, featuring dishes like koshari (a mix of lentils, rice, pasta, and spicy tomato sauce), ful medames (stewed fava beans), and molokhia (a green leafy vegetable dish). Bread, known as "aish," is a staple of the Egyptian diet.`,
      },
      {
        title: "Modern Developments and Challenges",
        description: `Egypt is a rapidly developing country with a growing population and economy. It faces challenges such as managing urbanization, preserving cultural heritage, and addressing environmental issues like water scarcity and pollution. The country is also working to promote tourism and attract foreign investment.`,
      },
    ],
  },
  {
    id: 10,
    icon: "desti10",
    title: "Switzerland",
    description: "Switzerland",
    days: 17,
    price: "AED 45000.00",
    description:
      "Switzerland is a landlocked country in Central Europe, known for its stunning landscapes, high quality of life, and political neutrality. Here are some key highlights about Switzerland",
    details: [
      {
        title: "Natural Beauty",
        description: `Switzerland is renowned for its picturesque landscapes, including the Swiss Alps, pristine lakes, and charming villages. The country offers some of the most beautiful and varied scenery in Europe, with destinations like the Matterhorn, Lake Geneva, Lake Lucerne, and the Jungfrau region.`,
      },
      {
        title: "Cities and Towns",
        description: `Zurich: Switzerland's largest city and a major financial hub, Zurich is known for its vibrant cultural scene, historic old town, and beautiful lakeside setting.
Geneva: A global city known for its international organizations, including the United Nations and the Red Cross. Geneva is also famous for its picturesque lake and surrounding mountain views.
Bern: The capital city, Bern, is known for its well-preserved medieval architecture, including the Zytglogge clock tower and the Bear Park.
Lucerne: A charming city located on Lake Lucerne, known for its historic Chapel Bridge, Old Town, and proximity to the Alps.`,
      },
      {
        title: "Outdoor Activities",
        description: `Switzerland is a year-round destination for outdoor enthusiasts. In winter, it offers world-class skiing and snowboarding in resorts like Zermatt, St. Moritz, and Verbier. In summer, the country is ideal for hiking, mountain biking, and water sports.`,
      },
      {
        title: "Swiss Alps",
        description: `The Alps are a major attraction for tourists and locals alike. They offer breathtaking views, alpine villages, and numerous recreational opportunities. The region is also home to famous peaks like the Matterhorn and Mont Blanc.`,
      },
      {
        title: "Swiss Cuisine",
        description: `Swiss cuisine is diverse and influenced by its German, French, and Italian neighbors. Popular dishes include fondue (melted cheese served with bread), raclette (melted cheese served with potatoes and vegetables), and rösti (potato dish). Swiss chocolate and Swiss watches are also renowned worldwide.`,
      },
      {
        title: "Neutrality and Diplomacy",
        description: ` Switzerland is known for its neutrality and has a long history of staying out of international conflicts. The country hosts various international organizations and diplomatic meetings, contributing to its role as a center for global diplomacy.`,
      },
      {
        title: "Swiss Banking and Finance",
        description: `Switzerland is a major financial center with a strong banking sector known for its stability, privacy, and sophisticated financial services. The Swiss Franc (CHF) is the country's currency.`,
      },
      {
        title: "Multilingualism",
        description: `Switzerland has four official languages: German, French, Italian, and Romansh. The country's multilingual nature is a reflection of its diverse cultural heritage and contributes to its unique national identity.`,
      },
      {
        title: "Public Transportation",
        description: `Switzerland has an efficient and extensive public transportation system, including trains, trams, and buses. The Swiss Travel Pass offers tourists unlimited travel on the public transport network and access to various attractions.`,
      },
      {
        title: "Quality of Life",
        description: `Switzerland consistently ranks high in global quality-of-life indices, with excellent healthcare, education, and living standards. The country's political stability, safety, and cleanliness contribute to its high quality of life.`,
      },
    ],
  },
  {
    id: 11,
    icon: "desti11",
    title: "Africa",
    description: "Africa",
    days: 15,
    price: "AED 62999.00",
    description:
      "Africa is the second-largest and second-most populous continent, rich in diversity, culture, and natural wonders. Its vastness encompasses a wide range of environments, cultures, and histories. Here are some key highlights about Africa",
    details: [
      {
        title: "Geography and Natural Wonders",
        description: `Sahara Desert: The world's largest hot desert, stretching across North Africa, known for its vast sand dunes and arid landscape.
Mount Kilimanjaro: Africa's highest peak, located in Tanzania, is a popular climbing destination with its snow-capped summit rising above the surrounding savannah.
Victoria Falls: One of the largest and most famous waterfalls in the world, located on the Zambezi River between Zambia and Zimbabwe.
Serengeti National Park: A renowned wildlife reserve in Tanzania known for its annual migration of wildebeest and zebras.
Great Rift Valley: A geological formation running from the Middle East to Mozambique, known for its dramatic landscapes and significant archaeological sites.`,
      },
      {
        title: "Cultural Diversity",
        description: `Africa is home to over 3,000 ethnic groups and languages, each with its own unique traditions, languages, and customs.
Major cultural festivals include the Timkat Festival in Ethiopia, the Maasai Mara Migration Festival in Kenya, and the Fes Festival of World Sacred Music in Morocco.`,
      },
      {
        title: "Historical Significance",
        description: `Egyptian Civilization: One of the world's oldest civilizations, known for its pyramids, temples, and contributions to writing, mathematics, and medicine.
Great Zimbabwe: The ruins of a medieval city in Zimbabwe, notable for its stone architecture and historical significance.
Apartheid Era: South Africa's historical period of racial segregation and the subsequent struggle for equality, highlighted by figures like Nelson Mandela.`,
      },
      {
        title: "Wildlife and Conservation",
        description: `Africa is renowned for its diverse wildlife, including the "Big Five" (lion, leopard, elephant, buffalo, and rhino) and other species like giraffes, hippos, and cheetahs.
Conservation efforts are ongoing to protect endangered species and their habitats, with numerous national parks and reserves established across the continent.
`,
      },
      {
        title: "Economy",
        description: `Africa has a diverse economy with significant contributions from agriculture, mining, tourism, and emerging industries. Countries like Nigeria, South Africa, and Kenya are key economic players.
The continent is rich in natural resources, including oil, minerals, and precious metals`,
      },
      {
        title: "Climate and Environment",
        description: `Africa's climate ranges from tropical in the central and western regions, arid in the Sahara Desert, to temperate in the southern regions.
The continent faces environmental challenges such as deforestation, desertification, and climate change impacts.`,
      },
      {
        title: "Major Cities",
        description: `Lagos: Nigeria's largest city, known for its bustling markets and vibrant culture.
Cairo: Egypt's capital, famous for its ancient history and cultural landmarks.
Nairobi: Kenya's capital, known for its wildlife conservation efforts and as a gateway to safaris.
Cape Town: South Africa's city known for its stunning landscapes, including Table Mountain and its vibrant cultural scene.`,
      },
      {
        title: "Languages",
        description: `Africa has thousands of languages and dialects, with major ones including Swahili, Arabic, French, Hausa, and Amharic. Many African countries are multilingual, with several languages spoken within a single nation.`,
      },
      {
        title: "Art and Music",
        description: `African art and music are diverse and influential, with traditional crafts like beadwork, masks, and textiles, as well as musical styles such as Afrobeat, reggae, and traditional drumming.`,
      },
      {
        title: "Development and Innovation",
        description: `Africa is experiencing growth in various sectors, including technology, education, and infrastructure. Innovations such as mobile banking and renewable energy are becoming increasingly prominent across the continent.`,
      },
    ],
  },
  {
    id: 12,
    icon: "desti12",
    title: "Italy",
    description: "Italy",
    days: 20,
    price: "AED 53000.00",
    description:
      "Italy, located in Southern Europe, is renowned for its rich history, art, culture, and cuisine. Here are some key highlights about Italy",
    details: [
      {
        title: "Historical and Cultural Heritage",
        description: `Rome: Italy’s capital, Rome, is known for its ancient history and landmarks such as the Colosseum, the Roman Forum, and the Pantheon. The city is also home to Vatican City, which houses St. Peter’s Basilica and the Sistine Chapel, famous for Michelangelo’s frescoes.
Florence: Known as the birthplace of the Renaissance, Florence is renowned for its art and architecture. Major attractions include the Uffizi Gallery, the Florence Cathedral (Duomo), and Michelangelo's David.
Venice: Famous for its canals, historic architecture, and the Grand Canal, Venice is a unique city built on a series of islands. Key landmarks include St. Mark’s Basilica and the Rialto Bridge.`,
      },
      {
        title: "Cuisine",
        description: `Italian cuisine is celebrated worldwide, with dishes such as pizza, pasta, risotto, and gelato being particularly popular. Each region has its own specialties, such as Sicilian cannoli, Tuscan steak, and Neapolitan pizza.
The country is also known for its high-quality wines, including Chianti, Barolo, and Prosecco.  `,
      },
      {
        title: "Landscapes and Regions",
        description: `Tuscany: Known for its rolling hills, vineyards, and charming towns like Siena and San Gimignano. The region is also famous for its art and Renaissance architecture.
Amalfi Coast: A stunning coastal area known for its dramatic cliffs, picturesque villages like Positano and Amalfi, and Mediterranean beauty.
Dolomites: A mountain range in northern Italy, ideal for skiing, hiking, and breathtaking views.`,
      },
      {
        title: "Fashion and Design",
        description: `Italy is a global leader in fashion and design, with major fashion houses such as Gucci, Prada, and Valentino based in cities like Milan. Milan is also a hub for design and architecture, hosting events like Milan Fashion Week and the Milan Design Week.`,
      },
      {
        title: "Art and Architecture",
        description: `Italy is home to some of the world’s most famous art and architectural masterpieces, from ancient Roman structures to Renaissance art and Baroque churches. Notable figures include Leonardo da Vinci, Michelangelo, and Raphael.`,
      },
      {
        title: "Cultural Festivals and Traditions",
        description: `Italy hosts numerous festivals and events, such as the Venice Carnival, Siena’s Palio horse race, and various local food and wine festivals. These events showcase the country's vibrant traditions and cultural heritage.`,
      },
      {
        title: "Language",
        description: `The official language of Italy is Italian. Various regional dialects and languages are also spoken throughout the country.`,
      },
      {
        title: "Tourism and Attractions",
        description: `Italy is a major tourist destination, attracting visitors to its historic cities, scenic countryside, and coastal regions. Popular activities include exploring ancient ruins, enjoying scenic drives, and experiencing local cuisine.`,
      },
      {
        title: "Historical Influence",
        description: `Italy’s historical influence extends from the Roman Empire to the Renaissance, shaping Western civilization through its contributions to art, science, and politics.`,
      },
      {
        title: "Economic and Political Landscape",
        description: `Italy is a member of the European Union and the G7, and it has a diverse economy with strong sectors in manufacturing, tourism, and agriculture. The country has a parliamentary republic system with a President as the head of state and a Prime Minister as the head of government.`,
      },
    ],
  },
];
