import React from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import { footerPhotos } from "../home/mock";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  const contactData = [
    {
      icon: <CiLocationOn size={18} />,
      description:
        "Burjuman Business Tower - Bur Dubai - Dubai - United Arab Emirates",
      url: "https://maps.app.goo.gl/ZxZM8QQCYyX5v1wr8",
    },

    // {
    //   icon: <IoCallOutline size={18} />,
    //   description: "(+977) 524-191-2022",
    // },
    {
      icon: <CiMail size={18} />,
      description: "support@tripflys.com",
      url: "mailto:tripflys@gmail.com",
    },
  ];

  return (
    <>
      <div>
        <Icon name="footimg" className="w-100 img-fluid" />
      </div>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="review_swiper">
              <Swiper
                centeredSlides={false}
                spaceBetween={20}
                speed={900}
                parallax
                rewind
                loop
                effect="slide"
                freeMode
                grabCursor={true}
                watchSlidesProgress
                slidesPerView={3}
                breakpoints={{
                  1200: { slidesPerView: 7 },
                  992: { slidesPerView: 6 },
                  768: { slidesPerView: 4 },
                  576: { slidesPerView: 3 },
                  0: { slidesPerView: 2 },
                }}
                autoplay={{
                  delay: 19000,
                }}
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination", // Target the pagination element
                }}
                modules={[Autoplay]}
              >
                {footerPhotos?.map((data, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="footer_slideimg text-center">
                        <Icon name={data?.icon} className="img-fluid" />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="swiper-pagination"></div>
            </div>
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid" />
                  </p>
                  <h5>
                    Alsami Toursim L.L.C is a leading global travel company. The
                    main service is providing tours
                  </h5>
                </div>
                <div className="footlink_box">
                  {contactData.map((item, index) => (
                    <div className="footlink_boxsub" key={index}>
                      <div>
                        <div className="foot_icon">{item.icon}</div>
                      </div>
                      <p>
                        <a href={item?.link}>{item.description}</a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Home
                  </p>
                  <p
                    onClick={() => {
                      navigate("/destination");
                    }}
                  >
                    Destination
                  </p>
                  <p>
                    <NavLink to={"/about-us"}>About us</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Legal</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Unique Selling Proposition</h6>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Global Reach
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    iGaming Expertise
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Innovation
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Higher success rates
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
        <div className="container">
          <div className="copy_main">
            <div className="copy-right">
              <p>Copyright © 2024 Alsami Toursim L.L.C</p>
              {/* <div className="d-flex gap-2 social_icon">
                <p>
                  <FaFacebook />
                </p>
                <p>
                  <FaInstagram />
                </p>
                <p>
                  <FaTwitter />
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
