import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../ui";
import { whyChooseData } from "../home/mock";
import Button from "../../ui/elements/Button";

const PackegDetail = () => {
  const id = useParams();
  const navigate = useNavigate();
  const packageDetail = whyChooseData.find((data) => data.id === +id?.id);

  if (!packageDetail) {
    return <h1>Package not found</h1>;
  }
  return (
    <div className="container">
      <div className="policy_text comman_space">
        <div className="row justify-content-center my-2 pt-lg-0 pt-2">
          <div className="col-lg-6 col-md-10 text-center">
            <div className="commantext">
              <h4>{packageDetail.place}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7 order-lg-1 order-2">
            {packageDetail.details?.map((data, index) => {
              return (
                <div className="commanOther">
                  <h4>{data?.title}</h4>
                  <p>{data?.description}</p>
                </div>
              );
            })}
          </div>
          <div className="col-lg-5 order-lg-2 order-1 ">
            <div className="bestpack_sub">
              <Icon name={packageDetail?.icon} className="w-100" />
              {/* <Icon name={packageDetail.icon} className="img-fluid" /> */}
              <div className="bestpack_subtext">
                <div className="tourbox">
                  <span>{packageDetail.days} Days Tours</span>
                </div>
                <h6>{packageDetail.place}</h6>
                <h4>{packageDetail.title}</h4>
                <p>{packageDetail.description}</p>
                <div className="packeg_price d-flex justify-content-between align-items-center">
                  <p>
                    {packageDetail.price} <span> | Per Person</span>
                  </p>
                  <Button
                    className="secondarybtn"
                    type="button"
                    onClick={() => navigate("/contact-us")}
                  >
                    Book Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackegDetail;
