import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import axios from "axios";
import { IoCallOutline, IoReload } from "react-icons/io5";
import { Icon } from "../../ui";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  const contactData = [
    {
      icon: <CiLocationOn size={25} />,
      title: "Location",
      description:
        "Burjuman Business Tower - Bur Dubai - Dubai - United Arab Emirates",
      url: "https://maps.app.goo.gl/ZxZM8QQCYyX5v1wr8",
    },

    // {
    //   icon: <IoCallOutline size={25} />,
    //   title: "Contact No.",
    //   description: "(+977) 524-191-2022",
    // },
    {
      icon: <CiMail size={25} />,
      title: "Email Address",
      description: "tripflys@gmail.com",
      url: "mailto:tripflys@gmail.com",
    },
  ];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
    // Simulate form submission
    setTimeout(() => {
      setLoading(false);
      setShowMessage(true);

      // Hide the message after 5 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }, 1500);

    // try {
    //   await axios.post("http://192.168.132.77:8000/contactus", formData);
    //   alert("Form submitted successfully!");
    //   setFormData({
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     phoneNumber: "",
    //   });
    // } catch (error) {
    //   alert("There was an error submitting the form. Please try again.");
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div className="container">
        <div className="py-3">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h6>Touch With Us</h6>
                <h4>
                  How <span>Can We Help?</span>
                </h4>
                <p>
                  Read authentic reviews and personal stories from fellow
                  travelers. Gain insights and inspiration from their
                  experiences to help you plan your perfect trip.
                </p>
              </div>
            </div>
          </div>
          <div className="source_box">
            {contactData.map((item, index) => (
              <div className="source_boxsub" key={index}>
                <div className="source_icon">{item.icon}</div>
                <div>
                  <h4>{item.title}</h4>
                  <p>
                    <a href={item?.url}>{item.description}</a>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="policy_text">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="contact-hero text-lg-center text-start">
                  <Icon name="contactUs" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="commantext contact_text ">
                  <h4 style={{ color: "black" }}>Contact Us</h4>
                  <p style={{ color: "black" }}>
                    <b>ALSAMI TOURISM L.L.C</b>
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="contact_form">
                  <div className="form_field">
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="phoneNumber">Phone Number*</label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <Button
                    className="primarybtn w-100"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <div className="loader"></div> : "Submit"}
                  </Button>
                  {showMessage && (
                    <div className="message pt-1">
                      Your form has been submitted successfully!
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
