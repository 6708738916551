export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const jouenryDays = [
  "1 days",
  "2 days",
  "3 days",
  "4 days",
  "5 days",
  "7 days",
];

export const tourFamily = ["Family Tour", "City Tour", "Travel Type"];

export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Featured Destinations",
    description:
      "From the bustling streets of New York City to the serene beaches of Bali, find the perfect destination for your next adventure. Check out our curated list of the most popular travel spots around the globe",
  },
  {
    icon: "tradingItemTwo",
    title: "Exclusive Tour Packages",
    description:
      "Experience the best tours and activities with our exclusive packages. Whether you're seeking thrilling adventures or relaxing getaways, our special deals are designed to provide exceptional value and unforgettable memories.",
  },
  {
    icon: "tradingItemThree",
    title: "Easy Booking Process",
    description:
      " Booking your next trip has never been easier. Our user-friendly platform allows you to find and book tours, activities, and accommodations in just a few clicks. Enjoy a seamless and efficient booking experience.",
  },
  {
    icon: "tradingItemFour",
    title: "Personalized Recommendations",
    description:
      "Get travel suggestions tailored to your interests and preferences. Whether you love adventure, culture, or relaxation, we provide personalized recommendations to help you find the perfect trip.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    id: 1,
    icon: "earn1",
    title: "Maldives",
    place: "Hurawalhi Island",
    days: 7,
    price: "AED 10999.00",
    description:
      "Our platform is designed by sports enthusiasts for sports enthusiasts, ensuring an immersive and authentic fantasy sports experience.",
    details: [
      {
        title: "Discover the Paradise of Hurawalhi Island, Maldives",
        description: `Sure! Here’s a more detailed description for the "Hurawalhi Island" Maldives tour package:Discover the Paradise of Hurawalhi Island, Maldives Embark on a luxurious 7-day journey to the enchanting Hurawalhi Island in the Maldives, where crystal-clear waters and pristine white-sand beaches await you. This exclusive tour package, priced at AED 10,999.00 per person, offers an unparalleled blend of relaxation and adventure, making it the perfect escape for those seeking both serenity and excitement.`,
      },
      {
        title: "Experience the Ultimate in Luxury and Leisure",
        description: `Hurawalhi Island is renowned for its breathtaking beauty and world-class amenities. From the moment you arrive, you'll be enveloped in a haven of tranquility. The island's luxurious accommodations are designed to provide maximum comfort and privacy, ensuring that your stay is nothing short of exceptional.`,
      },
      {
        title: "Immerse Yourself in Unmatched Natural Beauty",
        description: `Explore the stunning coral reefs and vibrant marine life that surround the island. Whether you’re snorkeling in the turquoise waters, lounging on the beach with a refreshing cocktail, or enjoying a romantic sunset cruise, Hurawalhi Island offers a plethora of activities that cater to your every desire.`,
      },
      {
        title: "A Fantasy Sports Experience Like No Other",
        description: `Our platform is meticulously designed by sports enthusiasts for enthusiasts, providing a unique and immersive fantasy sports experience. Engage in thrilling games and competitions that are both captivating and rewarding, all while enjoying the luxurious surroundings of Hurawalhi Island.`,
      },
      {
        title: "Exclusive Amenities and Personalized Service",
        description: `Indulge in gourmet dining experiences, unwind with spa treatments, and enjoy a range of recreational activities tailored to your preferences. The island’s dedicated staff are committed to providing personalized service, ensuring that your every need is met with the utmost care and attention.`,
      },
    ],
  },
  {
    id: 2,
    icon: "earn2",
    title: "Greece",
    place: "Santorini, Oia",
    days: 12,
    price: "AED 28000.00",
    description:
      "Enjoy a seamless gaming experience with our secure, reliable, and user-friendly interface that caters to both beginners and experts.",
    details: [
      {
        title: "Activities and Experiences",
        description: `Snorkeling and Diving: Explore the vibrant marine life of the Maldives with guided snorkeling and diving trips. Discover colorful coral reefs, exotic fish, and perhaps even spot a manta ray or sea turtle.`,
      },
      {
        title: "Accommodation and Amenities",
        description: `Hurawalhi Island features world-class accommodations designed to provide maximum comfort and luxury. Stay in beautifully appointed villas with stunning views of the ocean, and take advantage of exclusive amenities such as private pools, direct beach access, and personalized service. The resort’s facilities cater to your every need, ensuring a truly indulgent stay.`,
      },
      {
        title: "Description",
        description: `Our platform is tailored for sports enthusiasts, offering an immersive and authentic experience that blends luxury with adventure. The Maldives package at Hurawalhi Island promises an exceptional holiday experience with top-notch amenities and a range of activities designed to cater to diverse interests. Whether you're seeking relaxation, adventure, or a bit of both, this package provides a comprehensive and enriching getaway in one of the most beautiful destinations in the world. Enjoy the ultimate tropical escape with personalized service and unparalleled natural beauty.`,
      },
    ],
  },
  {
    id: 3,
    icon: "earn3",
    title: "Croatia",
    place: "Piazza Castello",
    days: 15,
    price: "AED 65000.00",
    description:
      "Compete in various leagues, earn points, and win fantastic prizes, making every match thrilling and rewarding.",
    details: [
      {
        title: "Historical Exploration",
        description: `Discover the rich history of Piazza Castello through guided tours of ancient castles, historic landmarks, and museums. Immerse yourself in the region's fascinating past and architectural marvels.`,
      },
      {
        title: "Cultural Immersion",
        description: `Engage with the local culture through various activities such as traditional Croatian cooking classes, folk dance performances, and visits to local markets. Experience the vibrant culture and traditions of Croatia.`,
      },
      {
        title: "Scenic Tours:",
        description: `Enjoy breathtaking scenic tours around Piazza Castello, including excursions to nearby natural wonders, picturesque villages, and stunning landscapes. Capture the beauty of Croatia's diverse terrain.`,
      },
      {
        title: "Leisure and Relaxation",
        description: `Unwind in luxurious accommodations and enjoy leisure activities such as spa treatments, gourmet dining, and relaxing strolls through charming streets and gardens.`,
      },
      {
        title: "Adventure and Exploration",
        description: `For those seeking adventure, partake in activities like hiking, cycling, and boat trips. Explore Croatia’s natural beauty and engage in thrilling outdoor experiences.`,
      },
    ],
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is ALSAMI TOURISM L.L.C?",
    content:
      " is a ALSAMI TOURISM L.L.C fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with ALSAMI TOURISM L.L.C?",
    content:
      "Download the ALSAMI TOURISM L.L.C app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "profile",
    title:
      "A very comfortable and memorable trip...worth the time and money ...thank you very much Tripflys & all staff",
    uerName: "Emily Johnson",
    position: "London",
  },
  {
    icon: "profile",
    title:
      "We travelled to dubai and it was amazing. The hospitality that we received starting from tour manager to the hotel was spectacular. Indeed we enjoyed a lot.",
    uerName: "David Smith",
    position: "America",
  },
  {
    icon: "profile",
    title:
      "I would highly recommend Tripflys to anyone looking for a memorable travel experience. Thank you again for a wonderful time!",
    uerName: "Medhvi Singh",
    position: "Dubai",
  },
  {
    icon: "profile",
    title:
      "I recently had the pleasure of booking a tour with Tripflys and it was truly an exceptional experience.",
    uerName: "Jehan Sebavala",
    position: "India",
  },
];

export const footerPhotos = [
  {
    icon: "review1",
  },
  {
    icon: "review2",
  },
  {
    icon: "review3",
  },
  {
    icon: "review4",
  },
  {
    icon: "review5",
  },
  {
    icon: "review6",
  },
  {
    icon: "review7",
  },
  {
    icon: "review8",
  },
  {
    icon: "review9",
  },
];
