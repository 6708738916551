import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import {
  clientReview,
  countries,
  jouenryDays,
  journeyData,
  tourFamily,
  whyChooseData,
} from "./mock";
import { useNavigate } from "react-router-dom";
import { MdMobileFriendly, MdOutlineDateRange } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import { RiDoubleQuotesR } from "react-icons/ri";
import { GiDetour } from "react-icons/gi";

const Home = () => {
  const navigate = useNavigate();

  const [country, setCountry] = useState("");
  const [date, setDate] = useState("");
  const [days, setDays] = useState([]);
  const [tour, setTour] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleDaysChange = (e) => {
    setDays(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleTourChange = (e) => {
    setTour(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Simulate form submission
    setTimeout(() => {
      setLoading(false);
      setShowMessage(true);

      // Hide the message after 5 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }, 1500); // 3 seconds delay
  };
  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="position-relative home_main ">
          <div className="container">
            <div className="hero-sec">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 scale-up-center my-2 order-lg-1 order-2">
                  <h1>
                    Explore The World <span>Start Planning Your Dream</span>{" "}
                    Trip Today!
                  </h1>
                  <p>
                    Find the best tours and travel deals to create unforgettable
                    memories. From city tours to exotic escapes, your next
                    adventure awaits.
                  </p>
                  <div>
                    <form onSubmit={handleSubmit} className="form_tour">
                      <div>
                        <input
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div>
                        <select
                          id="country"
                          value={country}
                          onChange={handleCountryChange}
                          className="form-control"
                        >
                          <option value="">--Select Country--</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          type="date"
                          placeholder="dd-mm-yy"
                          id="date"
                          value={date}
                          onChange={handleDateChange}
                          className="form-control"
                        />
                      </div>

                      <div>
                        <select
                          id="days"
                          value={days}
                          onChange={handleDaysChange}
                          className="form-control"
                        >
                          <option value="">--Select Days--</option>
                          {jouenryDays.map((days, index) => (
                            <option key={index} value={days}>
                              {days}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <select
                          id="days"
                          value={tour}
                          onChange={handleTourChange}
                          className="form-control"
                        >
                          <option value="">--Select Tour--</option>
                          {tourFamily.map((tour, index) => (
                            <option key={index} value={tour}>
                              {tour}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mybtn">
                        <Button type="submit" className="primarybtn w-100">
                          {loading ? (
                            <div className="loader"></div>
                          ) : (
                            "Book Now"
                          )}
                        </Button>{" "}
                        {showMessage && (
                          <div className="message pt-1">
                            Your form has been submitted successfully!
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 my-2 order-lg-2 order-1">
                  <div className="heroimg text-lg-center text-start">
                    <Icon name="Heroimg" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="get_app">
            <Icon name="icon1" className="img-fluid" />
            <Icon name="icon2" className="img-fluid" />
            <Icon name="icon3" className="img-fluid" />
            <Icon name="icon4" className="img-fluid" />
            <Icon name="icon5" className="img-fluid" />
            <Icon name="icon6" className="img-fluid" />
          </div>
        </div>
      </section>
      <section className="container" id="usp">
        <div className="start_withmain comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h6>Book with Confidence</h6>
                <h4>
                  Comprehensive <span>Travel Benefits</span>
                </h4>
                <p>
                  Our travel features include a user-friendly interface, curated
                  deals, personalized itineraries, and 24/7 support.
                  Effortlessly make your travel dreams come true.
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {journeyData.map((item, index) => {
              return (
                <div key={index} className="start_withbox">
                  <div className="start_withicon">
                    <Icon name={item?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container">
        <section id="whychoose" className="whychoos_animation">
          <div className="row justify-content-center my-2">
            <div className="col-lg-8 col-md-10 text-center">
              <div className="commantext">
                <h6>Choose Destination</h6>
                <h4>
                  Trending <span>World Wide </span>Destinations
                </h4>
                <p>
                  Discover the hottest travel spots worldwide, from exotic
                  beaches to vibrant cities. Find your next adventure among
                  these trending must-visit destinations.
                </p>
              </div>
            </div>
          </div>

          <div className="why_choosemain">
            <div className="why_choosebox">
              <div
                className="why_choosesub"
                onClick={() => navigate("/detail/11")}
              >
                <Icon name="desti11" className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>Cape Town</h5>
                  <p>Africa</p>
                </div>
              </div>
              <div
                className="why_choosesub"
                onClick={() => navigate("/detail/10")}
              >
                <Icon name="desti10" className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>Zurich</h5>
                  <p>Switzerland</p>
                </div>
              </div>
              <div
                className="why_choosesub extrawhy"
                onClick={() => navigate("/detail/4")}
              >
                <Icon name="city4" className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>Florida</h5>
                  <p>US</p>
                </div>
              </div>

              <div
                className="why_choosesub"
                onClick={() => navigate("/detail/5")}
              >
                <Icon name="desti5" className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>Paris</h5>
                  <p>France</p>
                </div>
              </div>
              <div
                className="why_choosesub"
                onClick={() => navigate("/detail/6")}
              >
                <Icon name="desti6" className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>Thailand</h5>
                  <p>Thailand</p>
                </div>
              </div>
            </div>
            <div className="position-relative extra_card">
              <Icon name="city3" className="img-fluid w-100" />
              <div className="whychoose_icontext">
                <h5>London</h5>
                <p>UK</p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section id="about" className="maximum_profirsec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 text-center">
              <div className="commantext">
                <h6>What We Are</h6>
                <h4>
                  Explore All <span> The World</span> With Us.
                </h4>
                <p>
                  Embark on unforgettable journeys with us. your gateway to
                  seamless travel and extraordinary adventures.
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center ">
            <div className="col-lg-5 ">
              <div className="aboutus_img">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 ">
              <div className="aboutus_text">
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Tripflys invites you to
                  discover the mesmerizing beauty of the earth. We are offering
                  a broad spectrum of services that include flight booking,
                  hotel bookings, leisure tours, adventure tours, cruise
                  bookings, Exclusive luxury hotel deals too and our foremost
                  priorities are the Domestic tour Packages and International
                  tour Packages. We have a vast classification of different
                  exotic destination packages.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; An Exceptionally Unique
                  Experience Travel to you ALSAMI TOURISM L.L.C Tours
                  specializes in crafting bespoke travel experiences tailored to
                  your preferences, offering personalized itineraries to
                  destinations across the globe. With a focus on luxury and
                  attention to detail, we ensure every journey with us is an
                  unforgettable adventure, curated to exceed your expectations
                  and create lasting memories.
                </p>
                {/* <NavLink to={"/about-us"} className="primarybtn">
                  Know More
                </NavLink> */}
              </div>
              <div className="about_iconmain">
                <p>
                  <MdMobileFriendly size={30} /> Friendly Price
                </p>
                <p>
                  <GiDetour size={30} />
                  Reliable Tour Package
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <section id="bestpackage" className="bestpack">
          <div className="row justify-content-between align-items-start my-2">
            <div className="col-lg-6 col-md-10">
              <div className="commantext">
                <h4>
                  Best <span>Tour Packages </span>
                </h4>
                <p>
                  Choose Tripflys for unmatched fantasy cricket with seamless
                  gameplay, real-time updates, fair play, and exciting rewards.
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end text-start">
              <p
                type="submit"
                className="viewlink"
                onClick={() => {
                  navigate("/destination");
                }}
              >
                View All Packages
              </p>
            </div>
          </div>

          <div className="bestpackage_main">
            {whyChooseData?.map((data, index) => {
              return (
                <div
                  className="bestpack_sub"
                  onClick={() => {
                    navigate(`/package-detail/${data.id}`);
                  }}
                >
                  <Icon name={data?.icon} className="w-100" />
                  <div className="bestpack_subtext">
                    <div className="tourbox">
                      <MdOutlineDateRange />{" "}
                      <span>{data?.days} Days Tours</span>
                    </div>
                    <h6>{data?.place}</h6>
                    <h4>{data?.title}</h4>
                    <p>{data?.description}</p>
                    <div className="packeg_price">
                      <p>
                        {data?.price} <span> | Per Person</span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>

      <section id="contact" className="comman_space">
        <div className="contact_main">
          <div className="container">
            <div
              className="row justify-content-center text-center position-relative"
              style={{ zIndex: 2 }}
            >
              <div className="col-lg-6 col-md-8 col-sm-10">
                <div className="contact_icon">
                  <Icon name="contactushome" className="img-fluid" />
                </div>
                <div className="contact_text">
                  <h4 className="mb-0">
                    Love Explore Your Life, <span>Travel We </span>You Want!
                  </h4>
                  <p>Get in touch with our team to learn more about</p>
                  <Button
                    className="primarybtn"
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Connect With Us
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="comman_space pt-0">
        <div className="container">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  <span>Traveler</span> Reviews & Stories
                </h4>
                <p>
                  Read authentic reviews and personal stories from fellow
                  travelers. Gain insights and inspiration from their
                  experiences to help you plan your perfect trip.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            {/* <div className="col-lg-3 text-center">
              <Icon name="Review" className="img-fluid" />
            </div> */}
            <div className="col-lg-9">
              <div className="review_swiper">
                <Swiper
                  centeredSlides={false}
                  spaceBetween={20}
                  speed={900}
                  parallax
                  rewind
                  loop
                  effect="slide"
                  freeMode
                  grabCursor={true}
                  watchSlidesProgress
                  slidesPerView={3}
                  breakpoints={{
                    1200: { slidesPerView: 2 },
                    992: { slidesPerView: 1 },
                    768: { slidesPerView: 1 },
                    576: { slidesPerView: 1 },
                    0: { slidesPerView: 1 },
                  }}
                  autoplay={{
                    delay: 19000,
                  }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Pagination]}
                >
                  {clientReview?.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="quat_main text-center">
                          <h4>
                            <div className="d-flex justify-content-center">
                              <RiDoubleQuotesR size={40} />
                            </div>
                            <span>{data?.title}</span>
                          </h4>
                          <div className="review_persone">
                            <Icon name={data?.icon} className="img-fluid" />
                            <h6>{data?.uerName}</h6>
                            <p>{data?.position}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <section>
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>Tripflys.com </span>
                </h4>
                <p>
                  Have questions? Our FAQ section provides essential answers to
                  help you get started and enjoy our platform easily.
                </p>
              </div>
            </div>
          </div>
          <div
            className="accordion accordion-flush selfaccord"
            id="accordionFlushExample"
          >
            {accordionFullData?.slice(0, 4)?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}1`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}1`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}1`}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div> */}

      {/* <Footer /> */}
    </>
  );
};

export default Home;
