import React from "react";
import { Icon } from "../../ui";
import { destiData } from "./mock";
import { useNavigate } from "react-router-dom";

const Destination = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <section id="whychoose" className="comman_space">
        <div className="row justify-content-center my-2">
          <div className="col-lg-6 col-md-10 text-center">
            <div className="commantext">
              <h4>
                Explore Top <span>World Wide </span>Destinations
              </h4>
              <p>
                Discover the hottest travel spots worldwide, from exotic beaches
                to vibrant cities. Find your next adventure among these trending
                must-visit destinations.
              </p>
            </div>
          </div>
        </div>

        <div className="destimain">
          {destiData?.map((data, index) => {
            return (
              <div
                className="position-relative desti_sub"
                onClick={() => {
                  navigate(`/detail/${data.id}`);
                }}
              >
                <Icon name={data?.icon} className="img-fluid w-100" />
                <div className="whychoose_icontext">
                  <h5>{data?.title}</h5>
                  {/* <p>{data?.description}</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Destination;
