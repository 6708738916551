import React from "react";
import { privacyPolicy } from "../../utils/mock";

const PrivacyPolicy = () => {

  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>Privacy Policy</h4>
                <p>
                  Welcome to Tripflys! Your privacy is important to us. This
                  Privacy Policy explains how we collect, use, and protect your
                  personal information when you use our website and services.
                </p>
              </div>
            </div>
          </div>
          <div>
            {privacyPolicy.map((section, index) => (
              <div className="commanOther" key={index}>
                <h4>{section.title}</h4>
                <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>

              </div>
            ))}
            
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;