import "./App.css";
import "animate.css";
import ScrolltoTop from "./ScrolltoTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./features/home/Home";
import Footer from "./features/footer/Footer";
import PrivacyPolicy from "./features/privacypolicy/PrivacyPolicy";
import TermsCondition from "./features/termscondition/TermsCondition";
// import RefundReturn from "./features/refundreturnpolicy/RefundReturn";
import Header from "./features/header/Header";
import AboutUs from "./features/about/AboutUs";
import ContactUs from "./features/contact/ContactUs";
import Destination from "./features/destination/Destination";
import PackegDetail from "./features/destination/PackegDetail";
import Detailpage from "./features/destination/Detailpage";

function App() {
  return (
    <>
      {/* <Home /> */}

      <Router>
        <Header />
        <ScrolltoTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-condition" element={<TermsCondition />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/destination" element={<Destination />}></Route>
          <Route path="/package-detail/:id" element={<PackegDetail />}></Route>
          <Route path="/detail/:id" element={<Detailpage />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
