import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from "../../ui";
import { journeyData } from "../home/mock";
import Button from "../../ui/elements/Button";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2 pt-lg-0 pt-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h6>About Us</h6>
                <h4>
                  Discover Our <span>Difference</span>
                </h4>
                <p>
                  Explore unforgettable journeys with TripFlys, where every
                  adventure is crafted to perfection.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7 order-lg-1 order-2">
              <div className="commanOther">
                <h4>Expertly Curated Experiences</h4>
                <p>
                  Our team of travel enthusiasts meticulously curates each trip
                  to offer unique, unforgettable experiences. We handpick
                  destinations, activities, and accommodations to ensure every
                  journey exceeds your expectations. Whether you seek adventure,
                  relaxation, or cultural immersion, our expertly designed
                  itineraries cater to all travel styles.
                </p>
              </div>
              <div className="commanOther">
                <h4>Personalized Travel Plans</h4>
                <p>
                  We believe every traveler is unique, and so are their travel
                  needs. Our personalized travel plans are crafted with you in
                  mind. By understanding your preferences and interests, we
                  create tailor-made trips that reflect your personality. From
                  custom itineraries to special requests, we make your travel
                  dreams a reality.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-2 order-1 text-lg-end text-center">
              <Icon name="aboutus" className="img-fluid imgborder" />
            </div>
          </div>
          <section className="mt-5">
            <div className="container">
              <div className="get_app">
                <Icon name="icon1" className="img-fluid" />
                <Icon name="icon2" className="img-fluid" />
                <Icon name="icon3" className="img-fluid" />
                <Icon name="icon4" className="img-fluid" />
                <Icon name="icon5" className="img-fluid" />
                <Icon name="icon6" className="img-fluid" />
              </div>
            </div>
          </section>

          <section id="usp">
            <div className="start_withmain comman_space pb-3">
              <div className="row justify-content-center my-2">
                <div className="col-lg-6 col-md-10 text-center">
                  <div className="commantext">
                    <h6>Book with Confidence</h6>
                    <h4>
                      Comprehensive <span>Travel Benefits</span>
                    </h4>
                    <p>
                      Our travel features include a user-friendly interface,
                      curated deals, personalized itineraries, and 24/7 support.
                      Effortlessly make your travel dreams come true.
                    </p>
                  </div>
                </div>
              </div>

              <div className="start_withsub">
                {journeyData.map((item, index) => {
                  return (
                    <div key={index} className="start_withbox">
                      <div className="start_withicon">
                        <Icon name={item?.icon} className="img-fluid" />
                      </div>
                      <div>
                        <h5>{item?.title}</h5>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <div
            className="row justify-content-center text-center position-relative"
            style={{ zIndex: 2 }}
          >
            <div className="col-lg-6 col-md-8 col-sm-10">
              {/* <div className="contact_icon">
                <Icon name="contactushome" className="img-fluid" />
              </div> */}
              <div className="contact_text">
                <h4 className="mb-0" style={{ color: "black" }}>
                  Love Explore Your Life, <span>Travel We </span>You Want!
                </h4>
                <p style={{ color: "black" }} className="pb-3">
                  Get in touch with our team to learn more about
                </p>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Connect With Us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
