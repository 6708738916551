export const termsSections = [
  {
    title: "1. Introduction - Who We Are and What We Do",
    content: `Welcome to tripflys.com. These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of our website located at tripflys.com (together or individually "Service") operated by ALSAMI TOURISM L.L.C ("we," "our," "us").
    Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.`,
  },
  {
    title: "2. Acceptance of Terms",
    content: `By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.`,
  },
  {
    title: "3. Changes to Terms",
    content: `We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.`,
  },
  {
    title: "4. Use of Our Service",
    content: `Eligibility: You must be at least 18 years old to use our Service.
    Account: When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
    Security: You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.`,
  },
  {
    title: "5. Bookings and Payments",
    content: `Bookings: All bookings made through our Service are subject to availability and confirmation. We reserve the right to cancel or refuse any booking for any reason.
    Payments: All payments must be made through the payment methods provided on our Site. By making a payment, you agree to pay the total price for the booking, including any applicable taxes and fees.
    Cancellations and Refunds: Our cancellation and refund policy is detailed separately on our Site. Please review this policy carefully before making a booking.`,
  },
  {
    title: "6. User Conduct",
    content: `You agree not to use the Service for any purpose that is unlawful or prohibited by these Terms. You agree not to:
    Use the Service in any manner that could disable, overburden, damage, or impair the Service or interfere with any other party's use of the Service.
    Attempt to gain unauthorized access to the Service, or other accounts, computer systems or networks connected to the Service through hacking, password mining or any other means.
    Obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Service.`,
  },
  {
    title: "7. Intellectual Property",
    content: `The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of ALSAMI TOURISM L.L.C and its licensors. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of ALSAMI TOURISM L.L.C.`,
  },
  {
    title: "8. Termination",
    content: `We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
    If you wish to terminate your account, you may simply discontinue using the Service.`,
  },
  {
    title: "9. Limitation of Liability",
    content: `In no event shall ALSAMI TOURISM L.L.C, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
Your use of or inability to use the Service;
Any unauthorized access to or use of our servers and/or any personal information stored therein;
Any interruption or cessation of transmission to or from the Service;
Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party;
Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service.`,
  },
  {
    title: "10. Governing Law",
    content: `These Terms shall be governed and construed in accordance with the laws of the country in which ALSAMI TOURISM L.L.C is headquartered, without regard to its conflict of law provisions.`,
  },
  {
    title: "11. Contact Us",
    content: `If you have any questions about these Terms, please contact us at : ALSAMI TOURISM L.L.C`,
  },
];

export const privacyPolicy = [
  {
    content: `Welcome to tripflys.com. ALSAMI TOURISM L.L.C ("we," "our," "us") operates tripflys.com (the "Site"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Site.`,
  },
  {
    title: "1. Information We Collect",
    content: `We may collect information about you in a variety of ways. The information we may collect on the Site includes`,
  },
  {
    title: "2. Personal Data:",
    content: ` Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.
Derivative Data: Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
Financial Data: Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.`,
  },
  {
    title: "3. Use of Your Information",
    content: `Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to: 
Create and manage your account.
Process your transactions and send you related information, including purchase confirmations and invoices.   
Administer sweepstakes, promotions, and contests.
Deliver targeted advertising, newsletters, and other information regarding promotions and the Site to you.
Improve our services, Site, and user experience.
Send you technical notices, updates, security alerts, and support and administrative messages.`,
  },
  {
    title: "4. Disclosure of Your Information",
    content: `We may share information we have collected about you in certain situations. Your information may be disclosed as follows: 
    
By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
Third-Party Service Providers: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. 
Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.`,
  },
  {
    title: "5. Security of Your Information",
    content:
      "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
  },
  {
    title: "6. Policy for Children",
    content:
      "We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.",
  },
  {
    title: "7. Changes to This Privacy Policy",
    content: `We may update this Privacy Policy from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. When we do, we will revise the updated date at the top of this page.`,
  },
  {
    title: "8. Contact Us",
    content: `If you have questions or comments about this Privacy Policy, please contact us at: ALSAMI TOURISM L.L.C`,
  },
];

export const returnRefund = [
  {
    content: `1. We will refund any amount left in your Unutilised Account if your Account gets suspended or removed
      a. Due to Our failure to provide Services,
      b. Any instruction received from any government or regulatory authority.`,
  },
  {
    content:
      "2. We will refund the Pre-Designated Amount paid by You in case any Contest is abandoned in accordance with these Terms.",
  },
  {
    content: `3. We shall deactivate Your Account pursuant to any direction issued by an appropriate government agency and/or competent authority. If We receive requisite instructions from such authority, We shall refund the deposited amount from Your Unutilised Account to the source account, subject to applicable processing fees.`,
  },
  {
    content: `4. In the event Our Services are not available due to reasons outside Our control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control, such as acts of god, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemic, epidemic, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of services available on Our Platform (each a “Force Majeure Event”), then We reserve the right to cancel any Contest and process refund of the Pre-Designated Amount.`,
  },
  {
    content:
      "5. In the event of a Public Contest involving only two Users, where both the Users have entered the same teams (including the same captain and vice-captain), the Prize Money Pool and the Platform Fee shall be equally divided between the Users. Your portion of the Prize Money Pool shall be credited into Your Winning Account and Your portion of the Platform Fee shall be credited in the Discount Bonus Account.",
  },
  {
    content:
      "6. If there is any request for withdrawal of amount deposited in Unutilised Account, such refund request shall be processed net of GST i.e. applicable 28% GST paid on the amount deposited in Unutilised Account shall not be eligible for refund as per applicable government laws.",
  },
];
